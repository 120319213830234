<template>
  <sign-page
    title-text="质保金打款预警"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :title-menus="[]"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  purchaseContractRequest as request
} from '../../api'

export default {
  computed: {
    tableActions () {
      let data = [{
        key: 'deal',
        label: '处理'
      },
      {
        key: 'data_file',
        label: '附件'
      }]
      return data
    },
    columnList: {
      get () {
        let data = [{
          title: '合同号',
          field: 'code',
          sort: true
        },
        {
          title: '合同名称',
          field: 'name',
          sort: true
        },
        {
          title: '签订单位',
          field: 'signedOrg',
          sort: true
        },
        {
          title: '采购负责人',
          field: 'principalUserName',
          sort: true
        },
        {
          title: '质保金',
          dataType: Number,
          field: 'warranty',
          sort: true
        },
        {
          title: '质保金打款日期',
          field: 'warrantyTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.warrantyTime ? rowData.warrantyTime.slice(0, 10) : '-')
          }
        },
        {
          title: '剩余天数',
          field: 'lastWarrantyDay',
          sort: true
        },
        {
          title: '质保金打款状态',
          field: 'warrantyStatusText',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    async loadData () {
      let data = []
      if (this.$authFunsProxy.get) {
        data = await request.getWarrantyWarning()
      } else if (this.$authFunsProxy.getMyOrg) {
        data = await request.getWarrantyWarningMyOrg()
      }
      data.forEach(v => {
        v.warrantyStatusText = v.warrantyStatus === 'over' ? '已打款' : (v.warrantyTime ? '未打款' : '')
        v.endDealStatusText = v.endDealStatus === 'over' ? '已处理' : (v.endTime ? '未处理' : '')
      })
      data.sort((a, b) => a.lastWarrantyDay - b.lastWarrantyDay)
      return data
    },
    async tableAction (parm, vm) {
      let data = parm.data
      const result = await this.$dialog.confirm({title: '系统提示', content: '该合同已打款，取消系统预警?'})
      if (result) {
        await request.update(data.id, {
          warrantyStatus: 'over'
        })
        vm.loadData()
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
